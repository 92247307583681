@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Mono:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap');

* {
    scroll-behavior: smooth;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    
  }

  input:focus::placeholder {
    color: transparent;
  }

  @keyframes scroll {
    0% {
      left: 0;
    }
    100% {
      left: -100%;
    }
  }
  
  .animate {
    animation: scroll 15s linear infinite;
  }

  #day-zero {
    font-family: "DM Mono", monospace;
  }
  
